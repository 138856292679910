var cp_obj = CraftyPostcodeCreate();
cp_obj.set('access_token', 'c1ed3-80266-2bdab-00a70');
cp_obj.set('result_elem_id', 'lookUpResults');
cp_obj.set('res_autoselect', 0);
cp_obj.set('busy_img_url', 'templates/sn_webshop/img/uk/crafty_postcode_busy.gif');
cp_obj.set('on_result_selected', (function(a){
	var commaPosition = document.getElementById('jform_snprofile_address').value.indexOf(',');
	if(commaPosition !== -1){
		var buildingStreet = document.getElementById('jform_snprofile_address').value;
		var building = buildingStreet.slice(0,commaPosition).trim();
		var street = buildingStreet.slice(commaPosition+1).trim();
		document.getElementById('jform_snprofile_address').value = street;
		document.getElementById('jform_snprofile_building').value = building;
	}
}));
cp_obj.set('form', '');
cp_obj.set('elem_street1'  , 'jform_snprofile_address');
cp_obj.set('elem_town'  , 'jform_snprofile_postal');
cp_obj.set('elem_postcode' , 'jform_snprofile_postal_code');

var div = document.createElement("div");
div.classList.add('additional-hint');
div.innerHTML = Joomla.JText._('COM_SNV2_ZIP_CODE_HINT');
if(document.getElementById('lookForAddress')){
	document.getElementById('lookForAddress').parentElement.append(div);
	document.getElementById('lookForAddress').addEventListener('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		if(jQuery('#jform_snprofile_postal_code').valid()) {
			cp_obj.doLookup();
			return;
		}
	});
}

let isValidNumber = true;
jQuery('body').on('click', 'div.messageNumberIgnore', function () {
    isValidNumber = false
    jQuery('form#member-registration').validate().element('#jform_snprofile_address');
});

jQuery.validator.addMethod(
    'numberInAddress',
    function(value, element, checkRegexp) {
        if(!isValidNumber) {
            jQuery('.messageNumberIgnore').hide();
            return true;
        }
        let regexp = true;
        if(checkRegexp) {
            const hasNumber = /\d/;
            regexp = hasNumber.test(value);
        }
        let messageNumberIgnoreLength = jQuery('.messageNumberIgnore').length;
        if(regexp === true){
            jQuery('.messageNumberIgnore').hide();
        } else {
            jQuery('.messageNumberIgnore').show()
        }
        if(messageNumberIgnoreLength < 1){
            queueMicrotask(() => {
                jQuery("<div class='messageNumberIgnore'>"+Joomla.JText._('COM_SNV2_ADDRESS_MESSAGE_NUMBER_IGNORE')+"</div>").insertBefore('#jform_snprofile_address-error');
            })
        }
        return (this.optional(element) || (regexp) );
    },
    ""
);

APP.validators.registeruk = (function () {
	function validateRegisterForm(registerForm){
		registerForm.validate({
			validClass: 'success',
			rules: {
				'jform[email1]' : {
					required: true,
					email: true,
				},
				'jform[email2]' : {
					required: true,
					email: true,
					equalTo: '#jform_email1'
				},
				'jform[password1]' : {
					required: true,
					minlength: 6
				},
				'jform[password2]' : {
					required: true,
					minlength: 6,
					equalTo: '#jform_password1'
				},
				'jform[snprofile][salutation]' : {
					required: true
				},
				'jform[snprofile][installments]' : {
					required: true
				},
				'jform[snprofile][first_name]' : {
					required: true,
					minlength: 1
				},
				'jform[snprofile][last_name]' : {
					required: true,
					minlength: 2
				},
				'jform[snprofile][postal]' : {
					required: true,
					minlength: 2
				},
				'jform[snprofile][address]' : {
					required: true,
					minlength: 2,
                    numberInAddress: true
				},
				'jform[snprofile][postal_code]' : {
					required: true,
                    regex: '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$'
				},
				'jform[snprofile][phone]' : {
					required: true,
					minlength: 10,
					maxlength: 11,
					digits: true
				},
				'jform[tos]' : {
					required: true
				},
				'tos' : {
					required: true
				},
				'basket_regulation' : {
					required: true
				},
				'jform[upgrade]' : {
					required: true
				}
			},
			messages : {
				'jform[email1]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					'email': Joomla.JText._('COM_SNV2_EMAIL_NOT_VALID')
				},
				'jform[email2]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					email: Joomla.JText._('COM_SNV2_EMAIL_NOT_VALID'),
					equalTo: Joomla.JText._('COM_SNV2_EMAIL_VALIDATION_MESSAGE_2')
				},
				'jform[password1]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_PASSWORD_VALIDATION_MESSAGE')
				},
				'jform[password2]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_PASSWORD_VALIDATION_MESSAGE_2'),
					equalTo: Joomla.JText._('COM_SNV2_PASSWORD_VALIDATION_MESSAGE_2')
				},
				'jform[snprofile][salutation]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][installments]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][first_name]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_FIRST_NAME_SHORT_MESSAGE')
				},
				'jform[snprofile][last_name]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_LAST_NAME_SHORT_MESSAGE')
				},
				'jform[snprofile][postal]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_POSTAL_MESSAGE')
				},
				'jform[snprofile][postal_code]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					regex: Joomla.JText._('COM_SNV2_POSTAL_CODE_MESSAGE')
				},
				'jform[snprofile][address]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_ADDRESS_MESSAGE'),
                    // regex: Joomla.JText._('COM_SNV2_ADDRESS_MESSAGE_NUMBER'),
                    numberInAddress: Joomla.JText._('COM_SNV2_ADDRESS_MESSAGE_NUMBER'),
				},
				'jform[snprofile][phone]': {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED'),
					minlength: Joomla.JText._('COM_SNV2_MOBILE_VALIDATION_MESSAGE'),
					maxlength: Joomla.JText._('COM_SNV2_MOBILE_VALIDATION_MESSAGE')
				},
				'jform[tos]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'tos' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'basket_regulation' : {
					required: Joomla.JText._('COM_SNV2_BASKET_REGULATION_IS_REQUIRED')
				},
				'jform[upgrade]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][general_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][di_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][print_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][paperadvertise_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][email_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][landline_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][mobile_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
				'jform[snprofile][sms_consent]' : {
					required: Joomla.JText._('COM_SNV2_THIS_FIELD_IS_REQUIRED')
				},
			},
			errorClass: "has-error",
			errorPlacement: function(error, element) {
				element = jQuery(element);
				if(element.parent().hasClass('radio')){
					error.insertAfter(element.parent());
				} else if(element.attr('type') === 'checkbox') {
					error.appendTo(element.parent());
				} else if(element.hasClass('validate-email')){
					error.appendTo(element.parent());
				} else {
					error.insertAfter(element);
				}
			},
			highlight: function(element, errorClass, validClass){
				// ------ GA start ------ //
				var formTypeGA = 'Click full form';
				if(jQuery(element).closest('fieldset').attr('class') === 'default'){
					formTypeGA = 'Click short form';
				}
				// ------ GA end ------ //
				element = jQuery(element);
				if(element.attr('type') === 'checkbox'){
					element.parents('.control-group').addClass('controls--invalid');
				} else if(element.attr('type') === 'radio'){
					element.parent('fieldset').addClass('controls--invalid');
				}
				element.addClass(errorClass).removeClass(validClass);
			},
			unhighlight: function(element, errorClass, validClass){
				element = jQuery(element);
				if(element.attr('type') === 'checkbox'){
					element.parents('.control-group').removeClass('controls--invalid');
				} else if(element.attr('type') === 'radio'){
					element.parent('fieldset').removeClass('controls--invalid');
				}
				element.removeClass(errorClass).addClass(validClass);
			},
			submitHandler: function(form) {
				jQuery(form).find(':submit').attr('disabled', 'disabled');
				form.submit();
			},
			onSelect: function (suggestion) {
		    	jQuery(this).val(suggestion.value);
		    }
		});
	}

	return {
        validateRegisterForm: validateRegisterForm,
    };
}());
